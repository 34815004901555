import { render, staticRenderFns } from "./Overview1.vue?vue&type=template&id=923da8b2&scoped=true&"
import script from "./Overview1.vue?vue&type=script&lang=js&"
export * from "./Overview1.vue?vue&type=script&lang=js&"
import style0 from "./Overview1.vue?vue&type=style&index=0&id=923da8b2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "923da8b2",
  null
  
)

export default component.exports